
import { StudentActions } from "@/store/vm/people/student/enums";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import moment from "moment";

export default defineComponent({
  name: "student-list",
  components: {},
  props: {
    widgetClasses: String,
    peopleType: String,
  },
  emits: ["upsert", "del"],
  data() {
    return {
      loadingModule: true,
      moment: moment,
    };
  },
  setup(props, { emit }) {
    const store = useStore();
    const checked = ref(false);
    const keyword = ref("");
    const { t } = useI18n();

    const genderLabels = {
      0: "Nam",
      1: "Nữ",
      2: "Khác",
    };

    onMounted(async () => {
      await apply();
    });

    watch(keyword, async () => {
      await apply();
    });

    const apply = async () => {
      if (keyword.value !== "") {
        await store
          .dispatch(StudentActions.SEARCH_BY_NAME_OR_ID_OR_PHONE_OR_EMAIL, {
            keyword: keyword.value,
            isCommitToModuleList: true,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        await store.dispatch(StudentActions.GET_LIST_STUDENT).catch((e) => {
          console.log(e);
        });
      }
    };

    const list = computed(() => {
      return store.state.StudentModule.list;
    });

    const count = computed(() => {
      return store.state.StudentModule.totalCount;
    });

    const upsertStudent = (studentId) => {
      emit("upsert", studentId);
    };

    const modalUpsertId = store.state.StudentModule.modalUpsertId;

    const lang = {
      edit: t("common.edit"),
      delete: t("common.delete"),
    };

    return {
      genderLabels,
      list,
      checked,
      count,
      upsertStudent,
      modalUpsertId,
      lang,
      apply,
      keyword,
    };
  },
});
